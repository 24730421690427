import React, { Fragment, useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isLink, isParagraph } from "datocms-structured-text-utils"

import { AccordianArrow } from "../../UI/Svgs"
import { replaceHash } from "../../../utils"

const AccordianNew = ({ data }) => {
  const { hash } = useLocation()
  const [expanded, setExpanded] = useState("")

  function openAccordian(slug) {
    if (expanded === slug) return
    window.history.pushState(null, null, `#${slug}`)
    setExpanded(slug)
  }
  function closeAccordian() {
    setExpanded("")
  }

  useEffect(() => {
    if (hash.length === 0) return
    openAccordian(replaceHash(hash))
  }, [hash])

  return (
    <Fragment>
      {React.Children.toArray(
        data.map(item => (
          <Accordian
            key={item.id}
            question={item.question}
            body={item.body}
            slug={item.slug}
            expanded={expanded}
            openAccordian={openAccordian}
            closeAccordian={closeAccordian}
          />
        ))
      )}
    </Fragment>
  )
}

const Accordian = ({
  key,
  question,
  body,
  slug,
  expanded,
  openAccordian,
  closeAccordian,
}) => {
  const isOpen = slug === expanded
  return (
    <div>
      <span className="offset-anchor" id={slug} />
      <motion.div
        key={key}
        initial={false}
        onClick={() => openAccordian(slug)}
        className={`mx-auto mb-4 max-w-[820px] rounded-2xl border-2 border-yellow-link px-6 py-7 md:px-12`}
      >
        <div className="flex items-center justify-between">
          <p className="mr-4 cursor-pointer text-left text-base font-bold text-gray-1">
            {question}
          </p>
          <button
            onClick={closeAccordian}
            className="p-2"
            key="omar-key"
            name="omar-name"
            value="omar-value"
          >
            <AccordianArrow
              animate={{ rotate: isOpen ? 90 : 0, x: isOpen ? 10 : 0 }}
              transition={{ duration: 0.4, ease: [0.6, 0.05, -0.01, 0.9] }}
            />
          </button>
        </div>
        <motion.div
          key="content"
          initial={{ height: "0" }}
          animate={{ height: isOpen ? "100%" : "0" }}
          transition={{ duration: 0.4, ease: [0.6, 0.05, -0.01, 0.9] }}
          className="accordian-structured-text overflow-hidden"
        >
          <StructuredText
            data={body}
            renderBlock={({ record }) => {
              switch (record.__typename) {
                case "DatoCmsGatsbyimage":
                  return (
                    <GatsbyImage
                      image={record.image.gatsbyImageData}
                      alt={record.image.alt}
                      className="my-6 rounded-2xl"
                    />
                  )
                default:
                  return null
              }
            }}
            customRules={[
              renderRule(isLink, ({ node, children, key }) => {
                return (
                  <Link
                    key={key}
                    to={node.url}
                    className="text-[#fb7443] underline"
                  >
                    {children}
                  </Link>
                )
              }),
              renderRule(isParagraph, ({ children, key }) => {
                if (children[0].length === 0) return <br />
                return (
                  <p key={key} className="my-4">
                    {children}
                  </p>
                )
              }),
            ]}
          />
        </motion.div>
      </motion.div>
    </div>
  )
}

export default AccordianNew
