import { useStaticQuery, graphql } from "gatsby"

const useFaqPageData = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsFaqPage {
        nodes {
          quickLinksText
          accordian {
            name
            slug
            accordianQuestions {
              id
              question
              slug
              body {
                value
                links
                blocks {
                  __typename
                  ... on DatoCmsGatsbyimage {
                    id: originalId
                    image {
                      gatsbyImageData
                      alt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return data.allDatoCmsFaqPage.nodes[0]
}

export default useFaqPageData
