import React from "react"
import { Link as ScrollLink } from "react-scroll"

const CategoriesBox = ({ className, quickLinksTitle, links }) => {
  return (
    <div className={`${className} items-center md:flex`}>
      <p className="mb-2 mr-8 min-w-[150px] transform font-spartan text-sm font-bold text-gray-3 md:mb-0 md:mr-0 md:translate-y-[-2px]">
        {quickLinksTitle}
      </p>
      <div className="flex flex-wrap">
        {React.Children.toArray(
          links.map((link, index) => (
            <ScrollLink
              key={index}
              to={link.slug}
              smooth={true}
              duration={500}
              offset={-150}
              className="m-2 cursor-pointer whitespace-nowrap rounded-[18px] bg-gray-6 px-5 py-3 font-spartan text-base font-bold text-black transition-all duration-75 hover:bg-header-cta-dark hover:text-white"
            >
              {link.name}
            </ScrollLink>
          ))
        )}
      </div>
    </div>
  )
}

export default CategoriesBox
