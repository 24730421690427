import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import FAQs from "../components/FAQs"
import { Main } from "../components/UI"

const FaqsPage = ({ data }) => {
  const canonicalUrl = `https://thetoucan.app/faqs/`
  return (
    <Layout isHomePage>
      <Seo
        title="Toucan Giving - Monthly donations to your favourite charities"
        noIndex={data.allDatoCmsFaqPage.nodes[0].noIndex}
        canonicalUrl={canonicalUrl}
      />
      <Main className="bg-center">
        <FAQs />
      </Main>
    </Layout>
  )
}

export const query = graphql`
  {
    allDatoCmsFaqPage {
      nodes {
        noIndex
      }
    }
  }
`

export default FaqsPage
