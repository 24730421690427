import React, { useState } from "react"
import { Element } from "react-scroll"

import { SearhBar } from "../UI"
import AccordianSearch from "./components/AccordianSearch"
import Accordian from "./components/Accordian"
import FaqCategoriexBox from "../CategoriesBox/FaqCategoriesBox"
import { useFaqPageData } from "../../system/DatoCms/FaqPageData"

const FAQs = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [isFocus, setIsFocus] = useState(true)
  const { quickLinksText, accordian } = useFaqPageData()
  const allQuestions = []
  accordian.map(a =>
    a.accordianQuestions.map(question => allQuestions.push(question))
  )
  const inputHandler = e => {
    setSearchTerm(e.target.value)
  }

  const closeSearhBar = () => {
    setIsFocus(false)
  }

  const searchBarFocus = () => {
    setIsFocus(true)
  }

  return (
    <div className="mt-[-80px] md:mt-[-96px]">
      <div className="mb-[28px] rounded-b-[50px] bg-header-cta px-4 pt-[137px] pb-[42px] md:pt-[200px] md:pb-[100px]">
        <div className="mx-auto max-w-[760px]">
          <h1 className="custom-text-shadow mb-8 text-center font-spartan text-4xl font-bold leading-[52px] text-white md:mb-16 md:text-[64px]">
            You ask the questions
          </h1>
          <div className="relative">
            <SearhBar onChange={inputHandler} onFocus={searchBarFocus} />
            <AccordianSearch
              searchTerm={searchTerm}
              items={allQuestions}
              isFocus={isFocus}
              closeSearhBar={closeSearhBar}
            />
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-screen-2xl px-4 font-spartan lg:px-20 xl:px-20">
        <div className="mb-8">
          {/* ----------------------- Quick Links Section ----------------------- */}
          <FaqCategoriexBox
            links={accordian}
            quickLinksTitle={quickLinksText}
            className="mt-20 mb-16"
          />
          {/* ----------------------- Accordian Section ----------------------- */}
          {React.Children.toArray(
            accordian.map(a => (
              <Element className="mb-6 md:mb-20" name={a.slug}>
                <p className="mb-4 text-[18px] font-bold text-gray-4 md:text-[36px]">
                  {a.name}
                </p>
                <Accordian data={a.accordianQuestions} />
              </Element>
            ))
          )}
          <p>
            Can’t find what you’re looking for? Contact us on{" "}
            <a
              href="mailto:support@thetoucan.app"
              className="font-bold text-yellow-link"
            >
              support@thetoucan.app
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default FAQs
