import React, { useRef } from "react"
import Fuse from "fuse.js"

import { useClickOutside } from "../../../hooks"

const AccordianSearch = ({
  className,
  items,
  searchTerm,
  isFocus,
  closeSearhBar,
}) => {
  const ref = useRef(null)
  const fuse = new Fuse(items, {
    keys: ["question", "slug"],
    includeScore: true,
    includeMatches: true,
  })
  const hits = fuse.search(searchTerm)
  useClickOutside(ref, closeSearhBar)

  const updateHash = hash => {
    window.location.hash = hash
  }

  return (
    hits.length !== 0 &&
    isFocus && (
      <div
        className={`${className} absolute top-[56px] left-0 right-0 z-10 max-h-[400px] overflow-y-scroll rounded-[28px] border-t-2 border-gray-200 bg-white px-4 py-6 shadow-social-feed`}
        ref={ref}
      >
        {hits.map(h => (
          <button
            className="mb-2 block w-full text-left border-[1px] border-gray-200 px-2 py-4 font-spartan text-base font-bold hover:bg-blue-900 hover:text-white"
            onClick={() => updateHash(h.item.slug)}
          >
            {h.item.question}
          </button>
        ))}
      </div>
    )
  )
}

export default AccordianSearch
